import scrollTransitions from "../transitions/scroll"
import isNewBrowser from "../helpers/isNewBrowser"

class ScrollAnimator {
	constructor() {
    this.init()
  }

  hide() {
    scrollTransitions.hideAll(this.DOM.scrollElements)
  }

  setup() {
    for (let i = 0; i < this.DOM.scrollElements.length; i++) {
      const scrollElement = this.DOM.scrollElements[i]
      scrollTransitions.reveal(scrollElement)
    }
  }

	init(container = document) {
    const scrollElements = container.querySelectorAll("[data-scroll]")

    if (scrollElements.length && isNewBrowser) {
      this.DOM = {}
      this.DOM.scrollElements = scrollElements

      this.hide()
      this.setup()
    }
	}
}

export default ScrollAnimator
