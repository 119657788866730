import Slider from "./Slider"

class SliderManager {
  constructor() {
    this.init()
  }

  setup() {
    for (let i = 0; i < this.DOM.wraps.length; i++) {
      const wrapOptions = this.DOM.wraps[i].dataset.sliderOptions
      const slider = this.DOM.wraps[i].querySelector(".js-slider")
      const sliderOptions = wrapOptions !== undefined ? JSON.parse(wrapOptions) : {}

      if (slider) {
        this.instances.push(new Slider(this.DOM.wraps[i], slider, sliderOptions))
      }
    }
  }

  init(container = document) {
    const wraps = container.querySelectorAll(".js-slider-wrap")

    if (wraps.length) {
      this.DOM = {}
      this.DOM.wraps = wraps

      this.instances = []

      this.setup()
    }
  }
}

export default SliderManager
