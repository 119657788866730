import { gsap } from "gsap"
import EmblaCarousel from "embla-carousel"
import ClassNames from "embla-carousel-class-names"

class Slider {
  constructor(wrap, sliderEl, options = {}) {
    this.init(wrap, sliderEl, options)
  }

  setup(options) {
    const sliderOptions = { ...options, align: "start", containScroll: "trimSnaps", skipSnaps: true }
    this.instance = EmblaCarousel(this.DOM.slider, sliderOptions, [ClassNames()])

    if (this.DOM.prev && this.DOM.next) {
      this.DOM.prev.addEventListener("click", this.goPrev)
      this.DOM.next.addEventListener("click", this.goNext)
      this.updateControls()
      this.instance.on("select", this.onSelect)
    }

    if (this.DOM.progressBar) {
      this.minProgress = 1 / this.DOM.slides.length
      gsap.set(this.DOM.progressBar, { scaleX: `${this.minProgress * 100}%` })
      this.instance.on("scroll", this.updateProgress)
    }
  }

  updateControls() {
    this.DOM.prev.classList.toggle("is-disable", !this.instance.canScrollPrev())
    this.DOM.next.classList.toggle("is-disable", !this.instance.canScrollNext())
  }

  updateProgress = () => {
    const progress = Math.max(this.minProgress, Math.min(1, this.instance.scrollProgress()))
    gsap.set(this.DOM.progressBar, { scaleX: `${progress * 100}%` })
  }

  onSelect = () => {
    this.updateControls()
  }

  goPrev = () => {
    this.instance.scrollPrev()
  }

  goNext = () => {
    this.instance.scrollNext()
  }

  init(wrap, sliderEl, options) {
    if (sliderEl) {
      this.DOM = {}
      this.DOM.wrap = wrap
      this.DOM.slider = sliderEl
      this.DOM.prev = this.DOM.wrap.querySelector(".js-slider-prev")
      this.DOM.next = this.DOM.wrap.querySelector(".js-slider-next")
      this.DOM.slides = this.DOM.wrap.querySelectorAll(".js-slide")
      this.DOM.progressBar = this.DOM.wrap.querySelector(".js-slider-progress-bar")

      this.instance = undefined

      this.setup(options)
    }
  }
}

export default Slider
