class Mindbody {
  constructor() {
    this.init()
  }

  onButtonClick = (target) => {
    const a = target.querySelector("a")
    if (a) a.click()
  }

  setup() {
    this.DOM.buttons.forEach((button) => {
      const buttonTarget = document.querySelector(`.js-mindbody-button-target[data-mindbody-button-id="${button.getAttribute('data-mindbody-button-id')}"]`)

      if (!buttonTarget) return
      button.addEventListener("click", () => this.onButtonClick(buttonTarget))
    })
  }

  init(container = document) {
    const mindbodyButtons = container.querySelectorAll(".js-mindbody-button")

    if (mindbodyButtons.length) {
      this.DOM = {}
      this.DOM.buttons = mindbodyButtons

      this.setup()
    }
  }
}

export default Mindbody
