import { gsap } from "gsap"
import iman from "../components/InstanceManager"

import { removeElement } from "../helpers/dom"

export const animateLoad = () => {
  const pageLoad = document.querySelector(".js-page-load")
  iman.map("pageHide")
  
  if (pageLoad) {
    const loadTl = gsap.timeline({
      onComplete: () => {
        removeElement(pageLoad)
        iman.map("pageReveal")
      }
    })

    loadTl
      .to(pageLoad, {
        opacity: 0,
        ease: "none",
        duration: 0.5,
      })
  }
}
