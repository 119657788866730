import { gsap } from "gsap"
import { ease, expo } from "../helpers/animation"

export default class HeroPage {
	constructor() {
    this.init()
  }

  pageHide() {
    if (this.DOM) {
      const animationsProps = { opacity: 0, y: 40 }

      if (this.DOM.title) {
        this.DOM.titleWords = this.DOM.title.querySelectorAll(".word")
        gsap.set(this.DOM.titleWords, {...animationsProps})
      }
      if (this.DOM.text) {
        this.DOM.textWords = this.DOM.text.querySelectorAll(".word")
        gsap.set(this.DOM.textWords, {...animationsProps})
      }

      if (this.DOM.animEls.length) {
        gsap.set(this.DOM.animEls, {...animationsProps})
      }
      if (this.DOM.image) {
        gsap.set(this.DOM.image, { opacity: 0, y: 100 })
      }
    }
  }

  pageReveal() {
    if (this.DOM) {
      const pageRevealTl = gsap.timeline()

      if (this.DOM.image) {
        pageRevealTl
          .to(this.DOM.image, {
            y: 0,
            opacity: 1,
            duration: 0.7,
            ease: expo
          })
      }

      if (this.DOM.animEls.length) {
        pageRevealTl
          .to(this.DOM.animEls, {
            y: 0,
            opacity: 1,
            stagger: 0.023,
            duration: 0.7,
            ease
          }, 0.1)
      }

      if (this.DOM.text) {
        pageRevealTl
          .to(this.DOM.textWords, {
            y: 0,
            opacity: 1,
            stagger: 0.023,
            duration: 0.7,
            ease
          }, 0.15)
      }

      if (this.DOM.title) {
        pageRevealTl
          .to(this.DOM.titleWords, {
            y: 0,
            opacity: 1,
            stagger: 0.023,
            duration: 0.7,
            ease
          }, 0.18)
      }
    }
  }

  init(container = document) {
    const wrap = container.querySelector(".js-hero-page")

    if (wrap) {
      this.DOM = {}
      this.DOM.wrap = wrap
      this.DOM.title = this.DOM.wrap.querySelector(".js-hero-page-title")
      this.DOM.text = this.DOM.wrap.querySelector(".js-hero-page-text")
      this.DOM.animEls = this.DOM.wrap.querySelectorAll(".js-hero-page-el")
      this.DOM.image = this.DOM.wrap.querySelector(".js-hero-page-image")
    }
  }
}
