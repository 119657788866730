import Splitting from "splitting"

class SplitText {
  initChars(container = document) {
    const charsItems = container.querySelectorAll("[data-split='chars']")
    if (charsItems.length > 0) {
      this.charsInstance = Splitting({
        target: charsItems,
        by: "chars",
      })
      this.instances.push(this.charsInstance)
    }
  }

  getInstance(instanceName) {
    const selectedInstance = this.instances.filter(instance => instance.id === instanceName)
    if (selectedInstance !== undefined) return selectedInstance[0]
  }

  addInstance(instanceName, options, save = true) {   
    this[instanceName] = Splitting(options)
    if (save) this.instances.push({ id: `${instanceName}`, value: this[instanceName] })
  }

  createInstance(options) {
    return Splitting(options)
  }

  removeInstance(instanceName) {
    const selectedInstances = this.instances.filter(instance => instance.id !== instanceName)
    this.instances = selectedInstances    
  }

  init() {
    this.instances = []
    this.initChars()
  }
}

const splitText = new SplitText()

export default splitText
