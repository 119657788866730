import { gsap } from "gsap"
import { ease } from "../helpers/animation"

class Menu {
	constructor() {
    this.init()

    this.lastScrollValue = 0
    this.startScrollUpValue = null
  }

  setup() {
    this.setupScroll()

    if (this.DOM.menuSubLinks.length) {
      this.attachArrows()

      if (!window.matchMedia("(hover: hover)").matches) {
        this.setupSubLink()
      }
    }
  }

  setupScroll() {
		window.addEventListener("scroll", this.onScroll, false)
  }

  attachArrows = () => {
    for (let i = 0; i < this.DOM.menuSubLinks.length; i++) {
      const subLink = this.DOM.menuSubLinks[i]
      
      subLink.insertAdjacentHTML("beforeend", `<div class="menu__sublink-icon"><svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1 1 4 4 4-4" stroke="#333" stroke-linecap="round" stroke-linejoin="round"/></svg></div>`)
    }
  }

  setupSubLink() {
    for (let i = 0; i < this.DOM.menuSubLinks.length; i++) {
      this.DOM.menuSubLinks[i].addEventListener("click", this.onSublinkClick)
    }
  }

  onScroll = () => {
    const scrollValue = window.pageYOffset || document.documentElement.scrollTop
    this.DOM.menu.classList.toggle("is-scroll-past", scrollValue > 10)

    if (scrollValue > this.lastScrollValue && !document.body.classList.contains('is-sub-open')) {
      if (!this._lastScrollValueDown) this._lastScrollValueDown = scrollValue
      if ((scrollValue - this._lastScrollValueDown) > 300) {
        this.DOM.menu.classList.add('is-scroll-down')
        this.startScrollUpValue = null
      }
    } else {
      this._lastScrollValueDown = null
      if (this.startScrollUpValue == null) {
        this.startScrollUpValue = scrollValue
      }

      if ((this.startScrollUpValue - scrollValue) > 300 || scrollValue < 300) {
        this.DOM.menu.classList.remove('is-scroll-down')
      }
    }

    this.lastScrollValue = scrollValue
  }

  onSublinkClick = (e) => {
    e.preventDefault()

    // switch menu
    for (let i = 0; i < this.DOM.menuSubLinks.length; i++) {
      const isClickedLink = this.DOM.menuSubLinks[i] == e.currentTarget
      if (!isClickedLink) {
        this.DOM.menuSubLinks[i].classList.remove("is-open")
        this.DOM.menuSubLinks[i].parentNode.classList.remove("is-open")
        continue
      }

      this.DOM.menuSubLinks[i].classList.toggle("is-open")
      this.DOM.menuSubLinks[i].parentNode.classList.toggle("is-open")
    }

    // clear body class
    let isSubOpen = false
    for (let i = 0; i < this.DOM.menuSubLinks.length; i++) {
      if (this.DOM.menuSubLinks[i].classList.contains("is-open")) {
        isSubOpen = true
        break
      }
    }
    if (!isSubOpen) {
      document.body.classList.remove("is-sub-open")
    } else {
      document.body.classList.add("is-sub-open")
    }
  }

  pageHide() {
    if (this.DOM) {
      gsap.set(this.DOM.menu, { opacity: 0, y: 40 })
    }
  }

  pageReveal() {
    if (this.DOM) {
      const pageRevealTl = gsap.timeline()
      pageRevealTl
        .to(this.DOM.menu, {
          opacity: 1,
          transition: 'transform 0.3s ease-out',
          y: 0,
          clearProps: "opacity,y",
          delay: 0.3,
          duration: 0.7,
          ease
        })
    }
  }

  init(container = document) {
    const menu = container.querySelector(".js-menu")

    if (menu) {
      this.DOM = {}
      this.DOM.menu = menu
      this.DOM.menuEnd = this.DOM.menu.querySelector(".js-menu-end")
      this.DOM.menuMain = document.getElementById("main-menu")
      this.DOM.menuTop = this.DOM.menu.querySelector(".js-menu-top")
      this.DOM.menuBottom = this.DOM.menu.querySelector(".js-menu-bottom")
      this.DOM.menuSubLinks = this.DOM.menuMain ? this.DOM.menuMain.querySelectorAll(".menu-item-has-children > a") : []

      this.setup()
    }
  }
}

export default Menu
