import { gsap } from "gsap"
import layout from "./Layout"
import { ease } from "../helpers/animation"

export default class HeroInternal {
	constructor() {
    this.init()
  }

  addListeners() {
    if (!layout.isTouch() && !this.hasListeners) {
      this.areaBounds = this.DOM.wrap.getBoundingClientRect()
      this.DOM.wrap.addEventListener("mousemove", this.onMoveScene, { passive: true })
      this.DOM.wrap.addEventListener("mouseLeave", this.onLeaveScene, { passive: true })
      this.hasListeners = true
    }
  }

  removeListeners() {
    if (this.hasListeners) {
      this.DOM.wrap.removeEventListener("mousemove", this.onMoveScene, { passive: true })
      this.DOM.wrap.removeEventListener("mouseLeave", this.onLeaveScene, { passive: true })
      this.hasListeners = false
    }
  }

  onMoveScene = (e) => {
    const relX = e.clientX - this.areaBounds.left
    const relY = e.clientY - this.areaBounds.top

    const xRange = gsap.utils.mapRange(0, layout.window.width, -15, 15, relX)
    const yRange = gsap.utils.mapRange(0, layout.window.height, -15, 8, relY)

    gsap.to(this.DOM.shapes, {
      x: (i, t) => xRange * t.dataset.speed,
      y: (i, t) => yRange * t.dataset.speed,
      stagger: 0.03,
      ease: "none",
      duration: 0.4
    })
  }

  onLeaveScene = () => {
    gsap.to(this.DOM.shapes, {
      x: 0,
      y: 0,
      ease: "power1",
      duration: 0.45
    })
  }

  pageHide() {
    if (this.DOM) {
      gsap.set([
        this.DOM.titleWords,
        this.DOM.author,
        this.DOM.shapes,
        this.DOM.scroll,
      ], {
        opacity: 0,
        y: 40
      })
    }
  }

  pageReveal() {
    if (this.DOM) {
      const pageRevealTl = gsap.timeline({
        onComplete: () => {
          this.addListeners()
        }
      })

      pageRevealTl
        .to([this.DOM.author, this.DOM.scroll], {
          y: 0,
          opacity: 1,
          duration: 0.7,
          ease
        })
        .to(this.DOM.titleWords, {
          y: 0,
          opacity: 1,
          stagger: 0.023,
          duration: 0.7,
          ease
        }, 0.1)
        .to(this.DOM.shapes, {
          y: 0,
          opacity: 1,
          stagger: 0.013,
          duration: 0.7,
          ease
        }, 0.15)
    }
  }

  init(container = document) {
    const wrap = container.querySelector(".js-hero-internal")

    if (wrap) {
      this.DOM = {}
      this.DOM.wrap = wrap
      this.DOM.title = this.DOM.wrap.querySelector(".js-hero-internal-title")
      this.DOM.titleWords = this.DOM.title.querySelectorAll(".word")
      this.DOM.author = this.DOM.wrap.querySelector(".js-hero-internal-author")
      this.DOM.shapes = this.DOM.wrap.querySelectorAll(".js-hero-internal-shape")
      this.DOM.scroll = this.DOM.wrap.querySelector(".js-hero-internal-scroll")

      this.hasListeners = false
    }
  }
}
