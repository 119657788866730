export function getRequest (url, params = {}, headers = {}) {
  let completeUrl = url

  if (params) {
    if (completeUrl.indexOf('?') < 0) {
      completeUrl = `${completeUrl}?`
    }

    Object.keys(params).map((paramKey) => {
      completeUrl = `${completeUrl}${paramKey}=${params[paramKey]}&`
    })
  }

  return new Promise((resolve, reject) => {
    fetch(completeUrl, { method: "GET", headers: headers })
    .then(response => response.text())
    .then(response => resolve(response))
    .catch((err) => { resolve('') })
  })
}
