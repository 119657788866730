import { gsap } from "gsap"
import Slider from "./Slider"
import { ease } from "../helpers/animation"
import mbWidgets from '../mb-widgets'
import Cookies from 'js-cookie'

class Pricing {
  constructor() {
    this.init()
  }

  setupArchive() {
    for (let i = 0; i < this.DOM.archiveSwitchOptions.length; i++) {
      this.DOM.archiveSwitchOptions[i].addEventListener("click", this.onSwitchClick)
    }

    this.setActiveValue()
    this.setLabel()
    this.setSliders()

    const notActiveSubs = this.DOM.archive.querySelectorAll(`.js-pricing-sub:not([data-id="${this.activeValue}"])`)
    gsap.set(notActiveSubs, {
      position: "absolute",
      top: 0,
      left: 0,
      opacity: 0,
      visibility: "hidden",
      pointerEvents: "none",
      zIndex: -1
    })
  }

  setActiveValue() {
    this.activeValue = this.DOM.archiveActiveSwitch.dataset.id
    this.activeLabel = this.DOM.archiveActiveSwitch.dataset.period
  }

  setLabel(label = this.activeLabel) {
    this.DOM.archiveSwitch.classList.toggle("is-discount", label === "annuali" || label === "trimestrale")
  }

  setSliders() {
    for (let i = 0; i < this.DOM.archiveSlidersWraps.length; i++) {  
      const sliderWrap = this.DOM.archiveSlidersWraps[i]
      const slider = sliderWrap.querySelector(".js-pricing-slider")

      if (slider) {
        const slides = slider.querySelectorAll(".js-slide")
        const draggable = slides.length >= 4
        sliderWrap.classList.toggle("is-active", draggable || window.innerWidth < 1024)

        this.slidersInstances.push(new Slider(sliderWrap, slider, {
          draggable,
          align: "start",
          breakpoints: {
            '(max-width: 1023px)': {
              draggable: true,
            },
          },
        }))

        // visualizzo/nascondo la barra di navigazione in base alla viewport e al numero di slide
        const bar = sliderWrap.querySelector(".pricing-slider__progress-wrap")
        const numSlides = slides.length
        const screenWidth = window.innerWidth

        if (numSlides <= 4 && screenWidth > 1350) {
          bar.style.display = 'none'
        } else if (numSlides <= 2 && screenWidth > 768) {
          bar.style.display = 'none'
        }
      }
    }
  }

  onSwitchClick = (e) => {
    e.preventDefault()

    const target = e.currentTarget
    const relativeId = target.dataset.id

    this.onSwitchSub(relativeId)
  }

  onSwitchSub = (id) => {
    if (!this.isAnimating) {
      this.isAnimating = true

      const activeSub = this.DOM.archive.querySelector(`.js-pricing-sub[data-id="${this.activeValue}"]`)
      const newActiveSub = this.DOM.archive.querySelector(`.js-pricing-sub[data-id="${id}"]`)

      this.DOM.archiveActiveSwitch.classList.remove("is-active")
      this.DOM.archiveActiveSwitch = this.DOM.archiveSwitch.querySelector(`.js-pricing-switch-option[data-id="${id}"]`)
      this.DOM.archiveActiveSwitch.classList.add("is-active")

      this.setLabel(this.DOM.archiveActiveSwitch.dataset.period)

      const switchTl = gsap.timeline({
        onComplete: () => {
          this.setActiveValue()
          this.isAnimating = false
        }
      })

      switchTl
        .to(activeSub, {
          opacity: 0,
          duration: 0.2,
          ease: "linear",
          onComplete: () => {
            gsap.set(activeSub, {
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0,
              visibility: "hidden",
              pointerEvents: "none",
              zIndex: -1
            })
            gsap.set(newActiveSub, {
              position: "relative",
              top: "auto",
              left: "auto",
              visibility: "visible",
              pointerEvents: "all",
              zIndex: 1
            })
          }
        })
        .to(newActiveSub, {
          opacity: 1,
          duration: 0.2,
          ease: "linear",
        })
    }
  }

  setupSingle() {
    console.log('Running setupSingle')
    const activeRadio = this.DOM.single.querySelector(".js-single-pricing-choice:checked")
    
    if (activeRadio) {
      this.setActiveChoice(activeRadio)
    } else {
      console.warn("No payment default checked choice selected.")
    }

    for (let i = 0; i < this.DOM.singleRadios.length; i++) {
      this.DOM.singleRadios[i].addEventListener("change", this.onChoiceChange)
    }

    const mindbodies = this.DOM.single.querySelectorAll(`.js-single-pricing-mindbody[data-widget]`)
    mindbodies.forEach((mindbody) => {
      const widgetKey = mindbody.dataset.widget.replace("$mindbody-", '')
      const widgetData = mbWidgets[widgetKey]
      if (widgetData) {
        mindbody.innerHTML = widgetData.code
      }
    })

    this.DOM.singleCta.addEventListener("click", this.onCtaPress)
  }

  onChoiceChange = (e) => {
    this.setActiveChoice(e.currentTarget)
  }

  setActiveChoice = (element) => {
    const choiceValue = element.value
    const bankType = element.dataset.bank

    this.DOM.single.classList.remove(`is-${this.activeChoice}`)

    this.activeChoice = bankType
    this.DOM.singleCta.dataset.id = choiceValue

    this.DOM.single.classList.add(`is-${this.activeChoice}`)
  }

  onCtaPress = (e) => {
    e.preventDefault()
    const value = e.currentTarget.dataset.id
    if (!value) return

    const mindbody = this.DOM.single.querySelector(`.js-single-pricing-mindbody[data-id="${value}"]`)

    // gestisco memorizzazione su cookie del click per tracciamento conversioni
    const widgetName = mindbody.getAttribute('data-widget')
    let cookieName = widgetName
    if (widgetName)  {
      cookieName = widgetName.replace('$mindbody-', '')
    } else {
      cookieName = 'hardcode'
    }
    Cookies.set('mindbody_widget', cookieName)

    if (mindbody) {
      const link = mindbody.querySelector('a')
      if (link) link.click()
    }
  }

  pageHide() {
    if (this.DOM && this.DOM.archive) {
      this.DOM.animEls = this.DOM.archive.querySelectorAll(".js-pricing-anim-el")
      gsap.set(this.DOM.animEls, { opacity: 0, y: 40 })
    }
  }

  pageReveal() {
    if (this.DOM && this.DOM.archive) {
      gsap.to(this.DOM.animEls, {
        opacity: 1,
        y: 0,
        duration: 0.8,
        stagger: 0.02,
        ease
      })
    }
  }

  init(container = document) {
    const archive = container.querySelector(".js-pricing")
    const single = container.querySelector(".js-single-pricing")

    this.DOM = {}

    if (archive) {
      this.DOM.archive = archive
      this.DOM.archiveSwitch = this.DOM.archive.querySelector(".js-pricing-switch")
      this.DOM.archiveSwitchOptions = this.DOM.archiveSwitch.querySelectorAll(".js-pricing-switch-option")
      this.DOM.archiveSlidersWraps = this.DOM.archive.querySelectorAll(".js-pricing-slider-wrap")
      this.DOM.archiveActiveSwitch = this.DOM.archiveSwitch.querySelector(".js-pricing-switch-option.is-active")

      this.activeLabel = this.DOM.archiveActiveSwitch.dataset.period
      this.slidersInstances = []
      this.isAnimating = false

      this.setupArchive()
    }

    if (single) {
      this.DOM.single = single
      this.DOM.singleCta = this.DOM.single.querySelector(".js-single-pricing-cta")
      this.DOM.singleRadios = this.DOM.single.querySelectorAll(".js-single-pricing-choice")

      this.activeChoice = undefined

      this.setupSingle()
    }
  }
}

export default Pricing
