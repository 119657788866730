import { gsap } from "gsap"
import layout from "./Layout"
import { ease } from "../helpers/animation"

export default class Hero {
	constructor() {
    this.init()
  }

  setTrigger() {
    if (!layout.isTouch()) {
      const mainScrollTl = gsap.timeline({
        scrollTrigger: {
          trigger: this.DOM.section,
          start: "top top+=10%",
          end: "bottom top",
          onEnter: () => {
            this.addListeners()
          },
          onLeave: () => {
            this.removeListeners()
          },
          onEnterBack: () => {
            this.addListeners()
          },
          onLeaveBack: () => {
            this.removeListeners()
          },
        }
      })
    }
  }

  addListeners() {
    if (!layout.isTouch() && !this.hasListeners) {
      this.areaBounds = this.DOM.wrap.getBoundingClientRect()
      this.DOM.wrap.addEventListener("mousemove", this.onMoveScene, { passive: true })
      this.DOM.wrap.addEventListener("mouseLeave", this.onLeaveScene, { passive: true })
      this.hasListeners = true
    }
  }

  removeListeners() {
    if (this.hasListeners) {
      this.DOM.wrap.removeEventListener("mousemove", this.onMoveScene, { passive: true })
      this.DOM.wrap.removeEventListener("mouseLeave", this.onLeaveScene, { passive: true })
      this.hasListeners = false
    }
  }

  onMoveScene = (e) => {
    const relX = e.clientX - this.areaBounds.left
    const relY = e.clientY - this.areaBounds.top

    const xRange = gsap.utils.mapRange(0, layout.window.width, -7, 7, relX)
    const yRange = gsap.utils.mapRange(0, layout.window.height, -7, 7, relY)

    gsap.to(this.DOM.shapes, {
      x: (i, t) => xRange * t.dataset.speed,
      y: (i, t) => yRange * t.dataset.speed,
      stagger: 0.03,
      ease: "power2.out",
      duration: 1
    })
  }

  onLeaveScene = () => {
    gsap.to(this.DOM.shapes, {
      x: 0,
      y: 0,
      ease: "power1",
      duration: 0.45
    })
  }

  pageHide() {
    if (this.DOM) {
      gsap.set([
        this.DOM.titleWords,
        this.DOM.author,
        this.DOM.subs,
        this.DOM.shapes,
        this.DOM.imgWrap
      ], {
        opacity: 0,
        y: 40
      })

      if (this.DOM.bgMobile) {
        gsap.set(this.DOM.bgMobile, {
          opacity: 0,
          y: 40
        })
      }
    }
  }

  pageReveal() {
    if (this.DOM) {
      const pageRevealTl = gsap.timeline({
        onComplete: () => {
          this.addListeners()
          this.setTrigger()
        }
      })

      pageRevealTl
        .to(this.DOM.subs, {
          y: 0,
          opacity: 1,
          stagger: 0.05,
          duration: 0.7,
          ease
        })
        .to(this.DOM.author, {
          y: 0,
          opacity: 1,
          duration: 0.7,
          ease
        }, 0.1)
        .to(this.DOM.titleWords, {
          y: 0,
          opacity: 1,
          stagger: 0.023,
          duration: 0.7,
          ease
        }, 0.15)
        .to([this.DOM.shapes, this.DOM.imgWrap], {
          y: 0,
          opacity: 1,
          stagger: 0.023,
          duration: 0.7,
          ease
        }, 0.2)

        if (this.DOM.bgMobile) {
          pageRevealTl.to(this.DOM.bgMobile, {
            opacity: 1,
            y: 0,
            duration: 0.7,
            ease
          }, "-=0.6")
        }
    }
  }

  init(container = document) {
    const wrap = container.querySelector(".js-hero")

    if (wrap) {
      this.DOM = {}
      this.DOM.wrap = wrap
      this.DOM.title = this.DOM.wrap.querySelector(".js-hero-home-title")
      this.DOM.titleWords = this.DOM.title.querySelectorAll(".word")
      this.DOM.author = this.DOM.wrap.querySelector(".js-hero-home-author")
      this.DOM.subs = this.DOM.wrap.querySelectorAll(".js-hero-home-sub")
      this.DOM.shapes = this.DOM.wrap.querySelectorAll(".js-hero-home-shape")
      this.DOM.imgWrap = this.DOM.wrap.querySelector(".js-hero-home-img")
      this.DOM.bgMobile = this.DOM.wrap.querySelector(".js-hero-home-bg-mobile")

      // show valid image on this.DOM.imgWrap 
      if (this.DOM.imgWrap && this.DOM.imgWrap.getAttribute('data-images')) {
        const dataImages = this.DOM.imgWrap.getAttribute('data-images')
        const dataImagesJson = JSON.parse(dataImages)
        
        const setRandomImage = (isFirst = false) => {
          const randomImage = dataImagesJson[Math.floor(Math.random() * dataImagesJson.length)]
          if (!randomImage) return
          const img = document.createElement('img')
          img.src = randomImage.sizes.large
          img.alt = randomImage.alt
          img.classList.add('hero-home__image')
          if (isFirst) {
            this.DOM.imgWrap.innerHTML = ''
            this.DOM.imgWrap.appendChild(img)
            setTimeout(() => { this.DOM.imgWrap.style.transition = 'opacity 0.25s ease' }, 2500)
          } else {
            this.DOM.imgWrap.classList.add('is-hidden')
            setTimeout(() => {
              this.DOM.imgWrap.innerHTML = ''
              this.DOM.imgWrap.appendChild(img)
              setTimeout(() => { this.DOM.imgWrap.classList.remove('is-hidden') }, 250)
            }, 250)
          }
        }
        this.DOM.imgWrap.addEventListener('click', () => setRandomImage())
        setRandomImage(true)
      }

      this.hasListeners = false
    }
  }
}
