import application from "./components/Application"
import tippy from 'tippy.js'
import jump from 'jump.js'
import Cookies from 'js-cookie'
import mbWidgets from './mb-widgets'
import LazyLoad from "vanilla-lazyload"

application.start()

// Shame

window.fbc = (eventName, eventParams = {}) => {
  console.log('Fbc ads', eventName, 'sent', eventParams)
  const baseUrl = 'https://asset.milanoyogaspace.com/meta-conversions-api.php'
  const formData = new FormData()
  formData.append('fbp', Cookies.get('_fbp'))
  formData.append('fbc', Cookies.get('_fbc'))
  formData.append('eventName', eventName)
  formData.append('eventParams', JSON.stringify(eventParams))
  try {
    fetch(baseUrl, {
      method: 'POST',
      body: formData
    })
  } catch (e) {
    console.error(e)
  }
}
 
document.addEventListener("DOMContentLoaded", () => {
  // Manage lazyload
  try {
    new LazyLoad({
      elements_selector: ".lazy"
    })
  } catch (e) {
    console.error('Error on lazyload')
    console.error(e)
  }

  // Manage hero-page__image-inner img src attribute
  try {
    const heroPageImageInner = document.querySelector('.hero-page__image-inner')
    const heroPageImageInnerMobile = document.querySelector('.hero-page__image-inner-mobile')
    if (heroPageImageInner && !heroPageImageInnerMobile) {
      const src = heroPageImageInner.getAttribute('data-src')
      if (src) {
        heroPageImageInner.setAttribute('src', src)
      }
    } else if (heroPageImageInnerMobile && !heroPageImageInner) {
      const src = heroPageImageInnerMobile.getAttribute('data-src')
      if (src) {
        heroPageImageInnerMobile.setAttribute('src', src)
      }
    } else if (heroPageImageInner && heroPageImageInnerMobile) {
      // attivo l'attributo src solo al tag che è visibile (non ha attiva la regola display: none)
      const src = heroPageImageInner.getAttribute('data-src')
      const srcMobile = heroPageImageInnerMobile.getAttribute('data-src')

      if (window.matchMedia('(max-width: 767px)').matches) {
        heroPageImageInnerMobile.setAttribute('src', srcMobile)
      } else {
        heroPageImageInner.setAttribute('src', src)
      }

      // in caso di resize attivo l'attributo src a entrambi i tag
      window.addEventListener('resize', () => {
        if (window.matchMedia('(max-width: 767px)').matches) {
          heroPageImageInnerMobile.setAttribute('src', srcMobile)
        } else {
          heroPageImageInner.setAttribute('src', src)
        }
      })
    }
  } catch (e) {
    console.error('Error on hero-page__image-inner img src attribute')
    console.error(e)
  }

  // Manage mindbody widgets (v2.0)
  try {
    const mbActivators = document.querySelectorAll('[data-mindbody-widget]')
    const mbLink = document.querySelector('.js-mindbody-widget-link')
    mbActivators.forEach((activator) => {
      activator.addEventListener('click', (e) => {
        e.preventDefault()

        const cb = () => {
          const widgetName = activator.dataset.mindbodyWidget
          let widgetData

          if (widgetName == 'hardcode') {
            widgetData = {
              type: 'link',
              code: atob(activator.getAttribute('data-hardcode'))
            }
          } else {
            widgetData = mbWidgets[widgetName]
          }

          if (!widgetData) {
            alert('Errore: widget non trovato')
            return
          }

          if (widgetData.type == 'link') {
            mbLink.innerHTML = widgetData.code

            const clickLink = (counter = 0) => {
              const mbLoader = document.querySelector('.mb-loader')
              if (mbLoader) mbLoader.classList.add('is-active')
              const link = mbLink.querySelector('a')
              if (!link && counter < 50) {
                setTimeout(() => {
                  clickLink(counter + 1)
                }, 250)
                return
              }

              if (!link) {
                if (mbLoader) mbLoader.classList.remove('is-active')
                console.error('Il widget Mindbody non risponde, riprova per favore.')
                console.log(widgetData)
                return
              }
              
              if (mbLoader) mbLoader.classList.remove('is-active')
              let cookieName = widgetName
              if (widgetName == 'hardcode') { // uso come cookie name il valore dell'attributo data-service-id di widgetData.code
                cookieName = mbLink.querySelector('healcode-widget')?.getAttribute('data-service-id')
                cookieName = cookieName ? `hardcode-${cookieName}` : 'hardcode'
              }
              Cookies.set('mindbody_widget', cookieName)
              link.click()
            }

            clickLink()
            return
          }

          if (widgetData.type == 'custom') {
            const customContainer = document.querySelector(`.js-mindbody-widget-custom-${widgetName}`)
            if (!customContainer) {
              console.error('Il widget Mindbody non risponde, riprova per favore.')
              console.log(widgetData)
              return
            }

            const customContents = customContainer.querySelectorAll('.js-nav-mindbody-tabs-content')
            if (customContents[0] && customContents[0].innerHTML.trim() == '') customContents[0].innerHTML = widgetData.code_1
            if (customContents[1] && customContents[1].innerHTML.trim() == '') customContents[1].innerHTML = widgetData.code_2

            Cookies.set('mindbody_widget', widgetName)
            customContainer.style.display = 'block'
            // document.body.style.overflow = 'hidden'
          }
        }

        const hasAds = activator.dataset.mindbodyWidgetAds
        if (hasAds && window.gtag) {
          window.fbc('conversion', {
            'send_to': hasAds
          })
          window.gtag('event', hasAds, {
            'event_callback': cb
          })
        } else {
          cb()
        }
      })
    })
    // pre-carico i widget custom
    Object.keys(mbWidgets).forEach((widgetName) => {
      const widgetData = mbWidgets[widgetName]
      if (widgetData.type != 'custom') return

      const customContainer = document.querySelector(`.js-mindbody-widget-custom-${widgetName}`)
      if (!customContainer) {
        console.error('Il widget Mindbody non risponde, riprova per favore.')
        console.log(widgetData)
        return
      }

      const mbActivatorsPreload = document.querySelectorAll(`[data-mindbody-widget="${widgetName}"]`)
      if (!mbActivatorsPreload.length) return

      const customContents = customContainer.querySelectorAll('.js-nav-mindbody-tabs-content')
      if (customContents[0] && customContents[0].innerHTML.trim() == '') customContents[0].innerHTML = widgetData.code_1
      if (customContents[1] && customContents[1].innerHTML.trim() == '') customContents[1].innerHTML = widgetData.code_2
    })
  } catch (e) {
    console.error('Error on mindbody widgets')
    console.error(e)
  }

  // Manage mindbody_widget cookie to send event to google analytics (if current page is /conferma)
  try {
    const mindbodyWidget = Cookies.get('mindbody_widget')
    if (mindbodyWidget && window.location.pathname.includes('/conferma')) {
      checkIfAnalyticsLoaded().then(() => {
        window.fbc(`conv_${mindbodyWidget}`, {})
        window.gtag('event', `conv_${mindbodyWidget}`, {})
        const mbWidget = mbWidgets[mindbodyWidget]
        console.log('found mbWidget', mbWidget)
        if (mbWidget && mbWidget.adsCode) {
          console.log('send to', mbWidget.adsCode)
          window.gtag('event', 'conversion', {
            'send_to': mbWidget.adsCode,
            'value': 1.0,
            'currency': 'EUR',
            'transaction_id': ''
          });
        }
      }).catch(() => {})

      Cookies.remove('mindbody_widget')
    }
  } catch (e) {
    console.error('Error on mindbody_widget cookie')
    console.error(e)
  }

  // Manage mindbody tabs
  try {
    const mindbodyTabs = document.querySelectorAll('.js-nav-mindbody-tabs')
    mindbodyTabs.forEach((el) => {
      const links = el.querySelectorAll('.js-nav-mindbody-tabs-link')
      const contents = el.querySelectorAll('.js-nav-mindbody-tabs-content')

      links.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault()

          links.forEach((link) => {
            link.classList.remove('active')
          })
          link.classList.add('active')

          const tab = link.dataset.tab
          contents.forEach((content) => {
            if (content.dataset.tab == tab) {
              content.classList.add('active')
            } else {
              content.classList.remove('active')
            }
          })
        })
      })
    })
  } catch (e) {
    console.error('Error on mindbody tabs')
    console.error(e)
  }

  // Manage fake overlay on all pages
  try {
    const fakeOverlay = document.querySelectorAll('.fake-overlay')
    fakeOverlay.forEach((el) => {
      const closeFunc = () => {
        el.classList.add('hidden')
        setTimeout(() => {
          el.style.display = 'none'
          el.classList.remove('hidden')
        }, 350)
      }
      
      const bg = el.querySelector('.fake-overlay__bg')
      bg.addEventListener('click', (e) => {
        e.preventDefault()
        closeFunc()
      })

      const close = el.querySelector('.fake-overlay__close')
      if (close) {
        close.addEventListener('click', (e) => {
          e.preventDefault()
          closeFunc()
        })
      }
    })
  } catch (e) {
    console.error('Error on fake overlay')
    console.error(e)
  }

  // Manage google ads conversion on link clicks
  try {
    const googleAdsConversion = document.querySelectorAll('[data-cta-ads]')
    googleAdsConversion.forEach((el) => {
      const adsCode = el.dataset.ctaAds
      if (!adsCode) return

      el.addEventListener('click', (e) => {
        if (!window.gtag) return
        e.preventDefault()

        console.log('Sending ads conversion', adsCode, 'to Google and Facebook')
        window.fbc('conversion', {
          'send_to': adsCode
        })
        window.gtag('event', adsCode, {
          'event_callback': () => {
            console.log('Google ads conversion sent', adsCode)
          }
        })

        setTimeout(() => {
          const href = el.getAttribute('href')
          if (!href) return

          const blank = el.getAttribute('target') == '_blank'
          if (blank) {
            window.open(href, '_blank')
          } else {
            window.location = href
          }
        }, 500)
      })
    })
  } catch (e) {
    console.error('Error on google ads conversion')
    console.error(e)
  }

  // Manage tippy.js on elements
  try {
    document.querySelectorAll('[data-tooltip]').forEach((el) => {
      tippy(el, {
        allowHTML: true,
        theme: 'light',
        content: el.dataset.tooltip,
        placement: 'bottom',
        maxWidth: 250,
        interactive: true
      })
    })
  } catch (e) {
    console.error('Error on tippy.js')
    console.error(e)
  }

  // Manage smooth scroll
  try {
    document.querySelectorAll('a[href^="#"]').forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault()
        if (el.getAttribute('href') == '#') return
        const target = document.querySelector(el.getAttribute('href'))
        if (!target) return
        jump(target, {
          duration: 500,
          offset: -100,
        })
      })
    })
  } catch (e) {
    console.error('Error on smooth scroll')
    console.error(e)
  }

  // Manage google maps iframe
  try {
    const googleMaps = document.querySelectorAll('.js-googlemaps')
    googleMaps.forEach((el) => {
      setTimeout(() => {
        if (!window._iub?.cs?.consent?.purposes?.[3]) {
          el.style.backgroundImage = `url(${el.dataset.bg})`
        }
      }, 2500)
    })
  } catch (e) {
    console.error('Error on google maps')
    console.error(e)
  }

  // Manage pricing card click (to fix css issue)
  try {
    const pricingCards = document.querySelectorAll('.sub-card')
    pricingCards.forEach((el) => {
      el.addEventListener('click', (e) => {
        const inputRadio = el.querySelector('.sub-card__radio')
        if (!inputRadio) return

        inputRadio.click()
      })
    })
  } catch (e) {
    console.error('Error on pricing card click')
    console.error(e)
  }

  // Manage testimonial read more
  try {
    const testimonialMore = document.querySelectorAll('.js-testimonial-more')
    testimonialMore.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault()
        const data = JSON.parse(el.getAttribute('data'))
        const fakeOverlay = document.querySelector('.js-testimonial-more-overlay')
        if (!fakeOverlay) return

        const fakeOverlayTitle = fakeOverlay.querySelector('.js-testimonial-more-overlay-title')
        const fakeOverlayDate = fakeOverlay.querySelector('.js-testimonial-more-overlay-date')
        const fakeOverlayContent = fakeOverlay.querySelector('.js-testimonial-more-overlay-content')
        if (!fakeOverlayTitle || !fakeOverlayDate || !fakeOverlayContent) return

        fakeOverlayTitle.innerHTML = data.title
        fakeOverlayDate.innerHTML = data.date
        fakeOverlayContent.innerHTML = data.content

        fakeOverlay.style.display = 'block'
      })
    })
  } catch (e) {
    console.error('Error on testimonial read more')
    console.error(e)
  }

  // Manage whatsapp button (show on mobile)
  try {
    const whatsappButton = document.querySelector('.js-whatsapp')
    if (whatsappButton) {
      if (window.matchMedia('(max-width: 768px)').matches) {
        whatsappButton.style.display = 'flex'
      }
    }
  } catch (e) {
    console.error('Error on whatsapp button')
    console.error(e)
  }

  // Manage custom overlay
  try {
    const customOverlay = document.querySelector('.js-custom-overlay')
    if (customOverlay) {
      const customOverlayButtons = document.querySelectorAll('.js-custom-overlay-button')
      customOverlayButtons.forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault()
          customOverlay.style.display = 'block'
        })
      })
    }
    const custom2Overlay = document.querySelector('.js-custom2-overlay')
    if (custom2Overlay) {
      const custom2OverlayButtons = document.querySelectorAll('.js-custom2-overlay-button')
      custom2OverlayButtons.forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault()
          custom2Overlay.style.display = 'block'
        })
      })
    }
  } catch (e) {
    console.error('Error on custom overlay')
    console.error(e)
  }

  // Hard fix for scroll animation
  // Fake a resize event to fix scroll animation every 250ms
  setInterval(() => {
    window.dispatchEvent(new Event('resize'))
  }, 500)

  // Manage form submit
  try {
  const forms = document.querySelectorAll('.js-form')
  forms.forEach((form) => {
    const feedbackContainer = form.querySelector('.js-form-feedback')
    const contentContainer = form.querySelector('.js-form-content')
    const loadingContainer = form.querySelector('.js-form-loading')

    // manage fileinputs
    const fileinputs = contentContainer.querySelectorAll('.fileinput')
    fileinputs.forEach((fileinput) => {
      const input = fileinput.querySelector('input[type="file"]')
      const button = fileinput.querySelector('.button')
      const buttonText = button.querySelector('.button__text')
      const buttonOriginalContent = buttonText.innerHTML

      input.addEventListener('change', (e) => {
        if (input.files.length > 0) {
          buttonText.innerHTML = 'Annulla'
        } else {
          buttonText.innerHTML = buttonOriginalContent
        }
      })

      button.addEventListener('click', (e) => {
        if (input.files.length > 0) {
          input.value = ''
          buttonText.innerHTML = buttonOriginalContent
        } else {
          input.click()
        }
      })
    })

    // listen submit event
    form.addEventListener('submit', async (e) => {
      e.preventDefault()
      loadingContainer.style.display = 'block'

      const formData = new FormData(form)

      const response = await fetch('https://asset.milanoyogaspace.com/contact-form-api.php', {
        method: 'POST',
        body: formData
      })
      const data = await response.json()
      console.log(data)
      
      if (!data.error) {
        feedbackContainer.classList.add('is-success')
      } else {
        feedbackContainer.classList.add('is-error')
      }
      loadingContainer.style.display = 'none'
      contentContainer.style.display = 'none'
      feedbackContainer.style.display = 'block'
    })
  })
  } catch (e) {
    console.error('Error on form submit')
    console.error(e)
  }
  
  // // Manage cursor
  // const cursor = document.querySelector('.cursor')

  // // if device is a mobile device, don't show cursor
  // if (window.matchMedia('(pointer: coarse)').matches) {
  //   cursor.style.display = 'none'
  // } else {
  //   cursor.style.display = 'block'
  //   const cursorWidth = cursor.offsetWidth
  //   const cursorHeight = cursor.offsetHeight
  //   document.addEventListener('mousemove', (e) => {
  //     const positionX = e.clientX - cursorWidth / 2
  //     const positionY = e.clientY - cursorHeight / 2

  //     cursor.style.transform = `translate(${positionX}px, ${positionY}px)`
  //   })

  //   const links = document.querySelectorAll('a, .js-pricing-switch-option, .js-mindbody-button, .js-accordion-head, .js-slider-next, .js-slider-prev, .menu__uptop a')
  //   links.forEach((link) => {
  //     link.addEventListener('mouseover', () => {
  //       cursor.classList.add('cursor--hover')
  //     })
  //     link.addEventListener('mouseout', () => {
  //       cursor.classList.remove('cursor--hover')
  //     })
  //   })
  //   const linksNegative = document.querySelectorAll('.js-slider-next, .js-slider-prev, .menu__uptop a')
  //   linksNegative.forEach((link) => {
  //     link.addEventListener('mouseover', () => {
  //       cursor.classList.add('cursor--hover-negative')
  //     })
  //     link.addEventListener('mouseout', () => {
  //       cursor.classList.remove('cursor--hover-negative')
  //     })
  //   })

  //   // listen when mouse go out of the window to hide the cursor
  //   window.addEventListener('mouseout', (e) => {
  //     if (e.relatedTarget == null) {
  //       cursor.style.display = 'none'
  //     }
  //   })

  //   // listen when mouse come back in the window to show the cursor
  //   window.addEventListener('mouseover', (e) => {
  //     if (e.relatedTarget == null) {
  //       cursor.style.display = 'block'
  //     }
  //   })
  // }

  // Make body opacity to 0 when user click on a link to other page
  // document.querySelectorAll('a').forEach((el) => {
  //   // if link is an anchor, don't do anything
  //   if (el.href.includes('#')) return
  //   // if link is a mailto, don't do anything
  //   if (el.href.startsWith('mailto')) return
  //   // if link is a tel, don't do anything
  //   if (el.href.startsWith('tel')) return
  //   // if link is a whatsapp, don't do anything
  //   if (el.href.startsWith('whatsapp')) return
  //   // if link is external, don't do anything
  //   if (el.href.startsWith('http') && !el.href.startsWith(window.location.origin)) return

  //   el.addEventListener('click', (e) => {
  //     document.body.style.opacity = 0
  //   })
  // })
  
  const navTopItemRandoms = document.querySelectorAll('.js-nav-topitem-random')
  if (navTopItemRandoms.length == 1) {
    navTopItemRandoms[0].style.display = 'block'
    navTopItemRandoms[0].classList.add('is-active')
  } else if (navTopItemRandoms.length > 1) {
    let activeIndex = 0
    navTopItemRandoms[activeIndex].style.display = 'block'
    navTopItemRandoms[activeIndex].classList.add('is-active')

    setInterval(() => {
      navTopItemRandoms[activeIndex].classList.remove('is-active')
      setTimeout(() => {
        navTopItemRandoms[activeIndex].style.display = 'none'
        activeIndex = activeIndex + 1
        if (activeIndex >= navTopItemRandoms.length) activeIndex = 0
        navTopItemRandoms[activeIndex].style.display = 'block'
        navTopItemRandoms[activeIndex].classList.add('is-active')
      }, 500)
      
    }, 3500)
  }

  const copyClipboard = document.querySelectorAll('.js-copy-clipboard')
  copyClipboard.forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault()
      if (!navigator.clipboard) return
      if (el.classList.contains('is-copied')) return

      const text = el.getAttribute('data-clipboard-text')
      navigator.clipboard.writeText(text)
      
      el.classList.add('is-copied')
      setTimeout(() => {
        el.classList.remove('is-copied')
      }, 2500)
    })
  })
})

let checkIfAnalyticsLoaded = () => {
  return new Promise((resolve, reject) => {
    let timeStart = Date.now();
    const TIMEOUT = 3000;

  let _isLoaded = function() {
    if (Date.now() - timeStart > TIMEOUT) {
      reject('Timeout. Google analytics not injected!');
      return;
    }
    if (window.gtag) {
      resolve();
      return;
    } else {
      setTimeout(_isLoaded, 500);
    }
  };

  _isLoaded();
  });
}
