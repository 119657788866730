import { gsap } from "gsap"

class Accordion {
  constructor(section) {
    if (section) {
      this.init(section)
    }
  }

  setup() {
    for (let i = 0; i < this.DOM.accordionItems.length; i++) {
      const accordionItem = this.DOM.accordionItems[i]
      const accordionHead = accordionItem.querySelector(".js-accordion-head")
      if (!accordionHead) continue
      accordionHead.addEventListener("click", () => this.onHeadClick(accordionItem))
    }
  }

  open = (item) => {
    const body = item.querySelector(".js-accordion-body")

    item.classList.add("is-active")
    body.classList.add("is-active")

    gsap.to(body, {
      height: "auto",
      ease: "power2.inOut",
      duration: 0.4
    })
  }

  close = (item) => {
    const body = item.querySelector(".js-accordion-body")
    
    item.classList.remove("is-active")
    body.classList.remove("is-active")

    gsap.to(body, {
      height: 0,
      ease: "power2.inOut",
      duration: 0.4
    })
  }

  closeAll = () => {
    for (let i = 0; i < this.DOM.accordionItems.length; i++) {
      const accordionItem = this.DOM.accordionItems[i]
      const body = accordionItem.querySelector(".js-accordion-body")

      accordionItem.classList.remove("is-active")
      body.classList.remove("is-active")
      gsap.to(body, {
        height: 0,
        ease: "power2.inOut",
        duration: 0.4
      })
    }
  }

  onHeadClick = (item) => {
    if (!item.classList.contains('is-active')) {
      this.closeAll()
      this.open(item)
    } else {
      this.close(item)
    }
  }

  init(section) {
    this.DOM = {}
    this.DOM.section = section
    this.DOM.accordion = this.DOM.section.querySelector(".js-accordion")
    this.DOM.accordionItems = this.DOM.accordion.querySelectorAll(".js-accordion-item")

    this.setup()
  }
}

export default Accordion
