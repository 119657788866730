import { getRequest } from "../helpers/ajax"
import { removeElement } from "../helpers/dom"
import scrollTransitions from "../transitions/scroll"

class Archive {
  constructor() {
    this.init()
  }

  setup() {
    this.addListener()
  }

  addListener() {
    if (!this.hasListeners) {
      this.DOM.moreButton.addEventListener("click", this.tryLoadMore)
      this.hasListeners = true
    }
  }

  removeListener() {
    if (this.hasListeners) {
      this.DOM.moreButton.removeEventListener("click", this.tryLoadMore)
      this.hasListeners = false
    }
  }

  setIsLoading(check) {
    this.isLoadingRequest = check
    this.DOM.archive.classList.toggle("is-loading", check)
  }

  tryLoadMore = (e) => {
    e.preventDefault()

    if (this.hasMore) {
      this.getAllRequest({
        is_ajax: "1"
      })
    }
  }

  getAllRequest(data) {
    this.setIsLoading(true)

    getRequest(this.DOM.moreButton.getAttribute("href"), data)
      .then((response) => {
        this.removeListener()
        removeElement(this.DOM.loadMore)
        this.DOM.loadMore = null
        this.DOM.moreButton = null

        this.DOM.container.insertAdjacentHTML("beforeend", response)

        this.DOM.loadMore = this.DOM.container.querySelector(".js-archive-load")
        this.DOM.moreButton = this.DOM.container.querySelector(".js-archive-load-more")
        this.hasMore = this.DOM.moreButton !== null
        if (this.hasMore) {
          this.addListener()
        }
        this.refreshScrollAnimation()
      })
      .catch((err) => {
        console.log("err", err)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  refreshScrollAnimation() {
    scrollTransitions.removeParallax()
    this.DOM.cardsShapes = this.DOM.container.querySelectorAll(".js-archive-card-shape")
    for (let i = 0; i < this.DOM.cardsShapes.length; i++) {
      scrollTransitions.initParallax(this.DOM.cardsShapes[i])
    }
  }

  init(container = document) {
    const archive = container.querySelector(".js-archive")

    if (archive) {
      this.DOM = {}
      this.DOM.archive = archive
      this.DOM.container = this.DOM.archive.querySelector(".js-archive-container")
      this.DOM.loadMore = this.DOM.container.querySelector(".js-archive-load")
      this.DOM.moreButton = this.DOM.container.querySelector(".js-archive-load-more")
      this.DOM.cardsShapes = this.DOM.container.querySelectorAll(".js-archive-card-shape")

      this.hasMore = this.DOM.moreButton !== null

      if (this.hasMore) {
        this.isLoadingRequest = false
        this.hasListeners = false

        this.setup()
      }
    }
  }
}

export default Archive
