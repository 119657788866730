import iman from "./InstanceManager"
import layout from "./Layout"
import ViewportFixer from "./ViewportFixer"
import Menu from "./Menu"
import SliderManager from "./SliderManager"
import Faq from "./Faq"
import Archive from "./Archive"
import Hero from "./Hero"
import HeroPage from "./HeroPage"
import HeroInternal from "./HeroInternal"
import Pricing from "./Pricing"
import Mindbody from "./Mindbody"
import ScrollAnimator from "./ScrollAnimator"
import splitText from "./SplitText"

import debounce from "../helpers/debounce"
import isTouch from "../helpers/isTouch"
import { animateLoad } from "../transitions/load"

class Application {
	start() {
		window.addEventListener("DOMContentLoaded", () => {
			document.documentElement.className = "js"
			document.body.classList.toggle("is-touch", isTouch())

			iman.add("mindbody", new Mindbody())
		})

		window.addEventListener("load", () => {
			layout.init()
			splitText.init()

			iman.add("viewportFixer", new ViewportFixer())
			iman.add("menu", new Menu())
			iman.add("hero", new Hero())
			iman.add("heroPage", new HeroPage())
			iman.add("heroInternal", new HeroInternal())
			iman.add("sliderManager", new SliderManager())
			iman.add("archive", new Archive())
			iman.add("pricing", new Pricing())
			iman.add("faq", new Faq())
			iman.add("scrollAnimator", new ScrollAnimator())

			animateLoad()
		})

		window.addEventListener("resize", debounce(this.onResize, 100))
		window.addEventListener("orientationchange", debounce(this.onResize, 100))
	}

	onResize = () => {
		layout.resize()
		document.body.classList.toggle("is-touch", isTouch())
		iman.map("resize")
	}
}

const application = new Application()

export default application
