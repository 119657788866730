/* ADS CODE 
conv-acquisto_ads { adsCode: 'AW-625271339/QGP2CJu6jbkZEKvEk6oC' }
*/

export default {
  //MODELLI
    // esempio_contratto: {
    //   type: 'link',
    //   code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="XXX" />'
    // },
    // esempio_pricing_option: {
    //   type: 'link',
    //   code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="XXXXXX" />'
    // },
    // esempio_prenota: {
    //   type: 'custom',
    //   code_1: '<healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="XXXXXXXXX" data-widget-version="1" ></healcode-widget>',
    //   code_2: '<healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="xxxxxxxxxx" data-widget-version="1" ></healcode-widget>'
    // },

  //MISC
    tesseramento: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="" data-service-id="100009" />'
    },
    account: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="loginRegister" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="account-link" data-inner-html="Login | Register"  />'
    },
    lezione_prova: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102289" />',
      adsCode: 'AW-625271339/QGP2CJu6jbkZEKvEk6oC'
    },
    lezione_prova_online: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102290" />',
      adsCode: 'AW-625271339/QGP2CJu6jbkZEKvEk6oC'
    },
    // prenota: {
    //   type: 'custom',
    //   code_1: '<healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="ec1949984a74" data-widget-version="1" ></healcode-widget>',
    //   code_2: '<healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="ec1792424a74" data-widget-version="1" ></healcode-widget>'
    // },
    prenota: {
      type: 'custom',
      code_1: '<div class="mindbody-widget" data-widget-type="Schedules" data-widget-id="ec29994a74"></div>',
      code_2: '<div class="mindbody-widget" data-widget-type="Schedules" data-widget-id="ec30034a74"></div>'
    },
    prenota_principianti: {
      type: 'custom',
      code_1: '<healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="ec1950004a74" data-widget-version="1" ></healcode-widget>',
      code_2: '<healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="ec1949994a74" data-widget-version="1" ></healcode-widget>'
    },

  //EVENTI
    //WIDGET ENRLOLLMENT. Conversione generica, perché lo chiede Thomas per Meta
      evento_teacher_training: {
        type: 'link',
        code: '<healcode-widget data-type="enrollments" data-widget-partner="object" data-widget-id="ec998444a74" data-widget-version="0" ></healcode-widget>'
      },

  //INGRESSI
    //STUDIO
      /*ingressi_30_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102172" />'
      },
      ingressi_30_under28_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102233" />'
      },*/
      ingressi_24_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102372" />'
      },
      ingressi_24_mattina_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102373" />'
      },
      ingressi_12_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102375" />'
      },
      ingressi_12_mattina_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102376" />'
      },
      ingressi_12_u28_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102377" />'
      },
      ingressi_24_u28_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102374" />'
      },
      ingressi_4_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102378" />'
      },
      ingressi_4_mattina_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102379" />'
      },
      ingresso_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="100003" />'
      },
    
    //ONLINE
      ingressi_12_online: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102091" />'
      },
      ingressi_12_u28_online: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102231" />'
      },
      ingressi_6_online: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="100012" />'
      },
      ingresso_online: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="100011" />'
      },

    //SOLO VIDEO
      giorni_3_video: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102268" />'
      },

  
  //-----PROMO-----//
    promo_4_ingressi_e_tess_studio: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102397" />'
    },
    promo_24_ingressi_studio: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102399" />'
    },
    promo_semestrale_open_studio: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102398" />'
    },
    promo_mensile_open_e_tess_studio: { //-----contratto------//
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="139" />'
    },
    promo_mensile_gravidanza_e_tess_studio: { //-----contratto------//
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="140" />'
    },
    promo_semestrale_open_rate_studio: { //-----contratto------//
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="141" />'
    },



    

  //ABBONAMENTI A PAGAMENTO SINGOLO
    //STUDIO
      annuale_open_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102389" />'
      },
      annuale_open_u28_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102183" />'
      },
      annuale_mattina_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102391" />'
      },
      semestrale_open_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102382" />'
      },
      semestrale_mattina_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102385" />'
      },

      /*----GRAVIDANZA-----*/
        mensile_gravidanza_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="126" />'
        },
        trimestrale_gravidanza_studio: { 
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102395" />'
        },
        trimstrale_gravidanza_u28_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102396" />'
        },
      /*-----fine gravidanza----*/
    
    
  //CONTRATTI
    //STUDIO
    
        annuale_open_rate_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="130" />'
        },
        annuale_open_u28_rate_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="131" />'
        },
        annuale_mattina_rate_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="129" />'
        },
        
        
        semestrale_open_rate_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="132" />'
        },
        semestrale_mattina_rate_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="133" />'
        },
        semestrale_open_u28_rate_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="134" />'
        },
        
      
        /*______*/
        mensile_open_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="137" />'
        },
        mensile_open_u28_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="138" />'
        },
        mensile_mattina_studio: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="136" />'
        },
  


    //ONLINE
      //PROMO

      //REGULAR
        trimestrale_open_online: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="115" />'
        },
        trimestrale_open_under28_online: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="120" />'
        },
        mensile_open_online: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="104" />'
        },
        mensile_open_under28_online: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="119" />'
        },
        mensile_gravidanza_online: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="118" />'
        },
        mensile_principianti_online: {
          type: 'link',
          code: '<healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="117" />'
        },

  
  //GIFT
    gift_595: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-gift-card-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="gift-card-link" data-inner-html="Buy Now" data-service-id="102403" />'
    },
    gift_290: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-gift-card-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="gift-card-link" data-inner-html="Buy Now" data-service-id="102402" />'
    },  
    gift_220: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-gift-card-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="gift-card-link" data-inner-html="Buy Now" data-service-id="102219" />'
    },
    gift_110: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-gift-card-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="gift-card-link" data-inner-html="Buy Now" data-service-id="102216" />'
    },
    gift_55: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-gift-card-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="gift-card-link" data-inner-html="Buy Now" data-service-id="102145" />'
    },
    gift_30: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-gift-card-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="gift-card-link" data-inner-html="Buy Now" data-service-id="102144" />'
    },

  //PRIVATE -- ok
    privata_x2: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102223" />'
    },
    privata_x3: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102224" />'
    },


  //NON TESSERATI
    ingressi_4_non_tesserati_studio: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102318" />'
    },
    mese_1_non_tesserati_studio: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102313" />'
    },
    ingresso_singolo_non_tesserati_studio: {
      type: 'link',
      code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102319" />'
    },
    

  //PROMO OLD 
    /*  
      promo_1_mese_tesseramento_studio: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102116" />'
      },
      promo_1_mese_online: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102213" />'
      },
      annuale_mattina_studio_promo: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102270" />'
      },
      annuale_open_studio_promo: {
        type: 'link',
        code: '<healcode-widget data-version="0.2" data-link-class="healcode-pricing-option-text-link" data-site-id="95395" data-mb-site-id="515023" data-bw-identity-site="false" data-type="pricing-link" data-inner-html="Buy Now" data-service-id="102269" />'
      },
    */
}