import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ease } from "../helpers/animation"

gsap.registerPlugin(ScrollTrigger)

const scrollTransitions = {
  parallaxInstances: [],
  hideAll: (elements) => {
    for (let i = 0; i < elements.length; i++) {
      const target = elements[i]
      const scrollType = target.dataset.scroll

      if (target) {
        switch (scrollType) {
          case "blocks":
            const animItems = target.querySelectorAll(".js-anim-el")
            const animAxis = target.dataset.scrollAxis || "y"
            gsap.set(animItems, { opacity: 0, [animAxis]: 50 })
            break
          case "slider":
            const sliderItems = target.querySelectorAll(".js-anim-el")
            const slides = target.querySelectorAll(".js-anim-slide")
            gsap.set(slides, { opacity: 0, x: 100 })
            gsap.set(sliderItems, { opacity: 0, y: 50 })
            break
          default:
            break
        }
      }
    }
  },
  initParallax: function(target) {
    const mm = gsap.matchMedia()
    mm.add({ isDesk: "(min-width: 100px)" }, (context) => {
      const { isDesk } = context.conditions

      if (isDesk) {
        const parallaxSpeed = parseFloat(target.getAttribute("data-speed"))
        const parallaxTl = gsap.timeline({
          scrollTrigger: {
            trigger: target,
            start: "top top+=90%",
            end: "bottom top+=30%",
            scrub: 1
          }
        })

        parallaxTl
          .fromTo(target, {
            y: (200 * parallaxSpeed),
          }, {
            y: -(200 * parallaxSpeed),
            ease: "none"
          })
        this.parallaxInstances.push(parallaxTl)
      }
    })
  },
  removeParallax: function() {
    for (let i = 0; i < this.parallaxInstances.length; i++) {
      this.parallaxInstances[i].kill()
    }
    this.parallaxInstances = []
  },
  reveal: function(target) {
    const scrollType = target.dataset.scroll

    switch (scrollType) {
      case "hero-main":
        const heroIcon = target.querySelector(".js-scroll-icon")
        const heroText = target.querySelector(".js-scroll-text")
        const heroTextChars = heroText.querySelectorAll(".char")

        const scrollTl = gsap.timeline({
          scrollTrigger: {
            trigger: target,
            start: "top top",
            end: "bottom top+=50%",
            scrub: 1,
          }
        })

        scrollTl
          .to(heroIcon, {
            opacity: 0,
            duration: 0.1
          })
          .to(heroTextChars, {
            opacity: 0,
            stagger: { amount: 0.1, from: "end" },
            duration: 0.1,
          }, 0)
        break
      case "banner":
        const bannerItems = target.querySelectorAll(".js-anim-el")
        const bannerImage = target.querySelector(".js-anim-img")
        const bannerTl = gsap.timeline({
          scrollTrigger: {
            trigger: target,
            start: "top top+=80%",
            end: "bottom top",
          }
        })

        bannerTl
          .from(target, {
            opacity: 0,
            duration: 0.7,
            ease: "none"
          })
          .from(bannerImage, {
            opacity: 0,
            duration: 0.7,
            ease: "none"
          }, 0.3)
          .from(bannerItems, {
            y: 40,
            opacity: 0,
            stagger: 0.04,
            duration: 0.7,
            ease
          }, 0.3)
        break
      case "slider":
        const sliderItems = target.querySelectorAll(".js-anim-el")
        const slides = target.querySelectorAll(".js-anim-slide")

        const sliderTl = gsap.timeline({
          scrollTrigger: {
            trigger: target,
            start: "top top+=80%",
            end: "bottom top",
          }
        })

        sliderTl
          .to(sliderItems, {
            y: 0,
            opacity: 1,
            stagger: 0.04,
            duration: 0.7,
            ease
          })
          .to(slides, {
            x: 0,
            opacity: 1,
            stagger: 0.04,
            duration: 0.7,
            ease
          }, 0.24)
        break
      case "blocks":
        const masterBlocksTl = gsap.timeline({
          scrollTrigger: {
            trigger: target,
            start: "top top+=80%",
            end: "bottom top",
          }
        })
        const animAxis = target.dataset.scrollAxis || "y"
        const animGroups = target.querySelectorAll(".js-anim-group")

        for (let i = 0; i < animGroups.length; i++) {
          const innerTl = gsap.timeline()
          const animGroup = animGroups[i]
          const innerItems = animGroup.querySelectorAll(".js-anim-el")

          if (innerItems.length > 0) {
            innerTl
              .to(innerItems, {
                [animAxis]: 0,
                opacity: 1,
                ease,
                duration: 0.75,
                stagger: 0.06,
                clearProps: `opacity,${animAxis}`,
              })

            masterBlocksTl.add(innerTl, i === 0 ? 0 : `-=${innerTl.duration() - 0.1}`)
          }
        }
        break
      case "parallax":
        this.initParallax(target)
        break
    }
  }
}

export default scrollTransitions
