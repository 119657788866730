import Accordion from "./Accordion"

class Faq {
  constructor() {
    this.init()
  }

  setup() {
    for (let i = 0; i < this.DOM.sections.length; i++) {
      this.instances.push(new Accordion(this.DOM.sections[i]))
    }
  }

  init(container = document) {
    const sections = container.querySelectorAll(".js-faq")

    if (sections.length) {
      this.DOM = {}
      this.DOM.sections = sections
      this.instances = []

      this.setup()
    }
  }
}

export default Faq
